import React from 'react';

const Layout2 = () => {
  return (
    <div>
      Layout 2 - no tourney
    </div>
  );
};

export default Layout2;

export const weighting = [
  1.00,
  0.90,
  0.59,
  0.47,
  0.39,
  0.34,
  0.31,
  0.28,
  0.26,
  0.24,
  0.23,
  0.21,
  0.20,
  0.19,
  0.18,
  0.18,
  0.17,
  0.16,
  0.16,
  0.15,
  0.15,
  0.14,
  0.14,
  0.14,
  0.13,
  0.13,
  0.13,
  0.12,
  0.12,
  0.12,
  0.12,
  0.11,
  0.11,
  0.11,
  0.11,
  0.11,
  0.10,
  0.10,
  0.10,
  0.10,
  0.10
]

export const pointPerThrowRef = {
  2.6: 10.58144474,
  2.8: 10.17541891,
  2.9: 9.978286348,
  3.0: 9.784972911,
  3.1: 9.595404615,
  3.2: 9.409508903,
  3.3: 9.227214625,
  3.4: 9.048452009,
  3.5: 8.873152634,
  3.6: 8.701249406,
  3.7: 8.53267653,
  3.8: 8.367369485,
  3.9: 8.205265002,
  4.0: 8.046301036,
  4.1: 7.890416743,
  4.2: 7.737552461,
  4.3: 7.587649681,
  4.4: 7.44065103,
  4.5: 7.296500244,
  4.6: 7.15514215,
  4.7: 7.016522644,
  4.8: 6.880588671,
  4.9: 6.747288203,
  5.0: 6.616570219,
  5.1: 6.488384688,
  5.2: 6.362682549,
  5.3: 6.239415688,
  5.4: 6.118536927,
  5.5: 6,
}